[data-theme="light"] {
  display: block;
}

[data-theme="dark"] {
  display: none;
}

.dark [data-theme="light"] {
  display: none;
}

.dark [data-theme="dark"] {
  display: block;
}

[data-rehype-pretty-code-fragment] {
  @apply relative;
}

[data-rehype-pretty-code-fragment] code {
  @apply grid min-w-full break-words rounded-none border-0 bg-transparent p-0 text-sm;
  counter-reset: line;
  box-decoration-break: clone;
}

[data-rehype-pretty-code-fragment] .line {
  @apply inline-block min-h-[1rem] w-full px-4 py-0.5;
}

[data-rehype-pretty-code-fragment] [data-line-numbers] .line {
  @apply px-2;
}

[data-rehype-pretty-code-fragment] [data-line-numbers] > .line::before {
  @apply text-xs text-zinc-50/40;
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  width: 1.8rem;
  margin-right: 1.4rem;
  text-align: right;
}

[data-rehype-pretty-code-fragment] .line--highlighted {
  @apply bg-zinc-700/50;
}

[data-rehype-pretty-code-fragment] .line-highlighted span {
  @apply relative;
}

[data-rehype-pretty-code-fragment] .word--highlighted {
  @apply rounded-md border-zinc-700/70 bg-zinc-700/50 p-1;
}

.dark [data-rehype-pretty-code-fragment] .word--highlighted {
  @apply bg-zinc-900;
}

[data-rehype-pretty-code-title] {
  @apply mt-2 px-4 pt-6 text-sm font-medium;
}

[data-rehype-pretty-code-title] + pre {
  @apply mt-2;
}

.mdx > .steps:first-child > h3:first-child {
  @apply mt-0;
}
